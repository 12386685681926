<template>
    <div>
        <div class="bg-secondary h-screen w-full">
            <div class="hidden lg:block">
                <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <h1 class="uppercase text-primary text-8xl tracking-extreme font-primary">Abora</h1>
<!--                    <h3 class="text-white text-2xl font-primary tracking-widest font-semibold">Web Development Specialists</h3>-->
<!--                    <p class="text-white text-lg font-primary mt-4">Bespoke Web Development and Support in the South West. Maintain, extend or build new with Abora. We ♥ PHP and JavaScript!</p>-->
                    <p class="font-primary text-lg text-primary mt-2"><a href="mailto:contact@abora.co.uk?subject=Website Enquiry">contact@abora.co.uk</a></p>
                </div>
            </div>
            <div class="block lg:hidden">
                <div class="absolute top-1/2 transform -translate-y-1/2 px-8">
                    <h1 class="uppercase text-primary text-5xl tracking-extreme font-primary">Abora</h1>
<!--                    <h3 class="text-white text-2xl font-primary tracking-widest font-semibold">Web Development Specialists</h3>-->
<!--                    <p class="text-white text-lg font-primary mt-4">Bespoke Web Development and Support in the South West. Maintain, extend or build new with Abora. We ♥ PHP and JavaScript!</p>-->
                    <p class="font-primary text-lg text-primary mt-2"><a href="mailto:contact@abora.co.uk?subject=Website Enquiry">contact@abora.co.uk</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>